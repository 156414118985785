import Network from '@/services/network'

export default function handleVoucherNo() {
	const network = new Network;
	
	const fetchVoucherNo = (query) => {
		return network.api('get', '/get-voucher-number'+query);
	}
	const fetchTXNNo = (query) => {
		return network.api('get', '/get-transaction-number'+query);
	}
	
	return {
		fetchVoucherNo,
		fetchTXNNo
	}
}